import { createStore, createHook } from "react-sweet-state";

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    isOpen: false,
  },
  // actions that trigger store mutation
  actions: {
    open:
      () =>
      ({ setState, getState }) => {
        // mutate state synchronously
        setState({
          isOpen: true,
        });
      },
    close:
      () =>
      ({ setState, getState }) => {
        // mutate state synchronously
        setState({
          isOpen: false,
        });
      },
  },
  // optional, unique, mostly used for easy debugging
  name: "CreateModalState",
});

export const useCreateModal = createHook(Store);
