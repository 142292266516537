export function BellRoundIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_686_7020)">
        <path
          d="M14.9999 23.5H16.9999M21 13V15.2396C21 15.7294 21.1798 16.2022 21.5052 16.5683L22.7808 18.0035C23.6407 18.9708 22.954 20.5 21.6597 20.5H10.3403C9.04598 20.5 8.35927 18.9708 9.21913 18.0035L10.4948 16.5683C10.8202 16.2022 11 15.7294 11 15.2396L11 13C11 10.2386 13.2386 8 16 8C18.7614 8 21 10.2386 21 13Z"
          stroke="#8E8E8E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <circle cx="16" cy="16" r="15.5" stroke="#242424" />
      </g>
      <defs>
        <clipPath id="clip0_686_7020">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
