import ArticlePreview from "../../components/ArticlePreview";
import { useParams } from "react-router-dom";
import FetchArticle from "../../utilities/FetchArticle";

export function Article() {
  const params = useParams();
  const article = FetchArticle(params?.id);

  return (
    <>
      {/* NewsStack */}
      <main className="font-[Inter]">
        {article && <ArticlePreview article={article} />}
      </main>
    </>
  );
}
