import React, { useContext, useEffect, useRef, useState } from "react";
import UploadSVG from "../utilities/UploadSvg";
import { dataSubmission } from "./DataSubmission";

import ReactModal from "react-modal";
import { toast } from "react-toastify";
import { Spinner } from "../utilities/icons/Spinner";
import { useCreateModal } from "../state/CreateModalState";

export const CreateModal = ({
  onShowPreviewChange,
}: {
  onShowPreviewChange?: (value: boolean) => void;
}) => {
  const [createModalState, createModalActions] = useCreateModal();

  const [lead, setLead] = useState("");
  const [body, setBody] = useState("");
  const [images, setImages] = useState<File[]>([]);
  const [showPreview, setShowPreview] = useState(false);
  const [rows, setRows] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [epicAlert, setEpicAlert] = React.useState(false);

  const [previewImage, setPreviewImage] = useState<string | ArrayBuffer | null>(
    null
  );

  //Make sure that the news feed do not appear when the preview is open too
  useEffect(() => {
    onShowPreviewChange && onShowPreviewChange(showPreview);
  }, [showPreview, onShowPreviewChange]);

  const inputFileRef = useRef<HTMLInputElement>(null);

  //This handle the submit post from the preview pop up and the main pop up create
  const handleSubmit = async (e) => {
    e.preventDefault();

    //show allert when lead is empty
    if (lead === "") {
      setShowAlert(true);
      return;
    }
    setShowAlert(false);

    try {
      setIsLoading(true);
      await dataSubmission({
        lead: lead,
        body: body,
        previewImage: previewImage,
      });
      handlePostModal();

      toast(
        "🎉 Your post has been created. Claim your Homespace to view posts when we launch.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        }
      );

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(`Error during post submission : ${error.message}`);
      toast.error("Unable to publish this post.");
    }
  };

  //Lenght limit for lead
  const handleLeadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 64) {
      setLead(event.target.value);
    }
  };

  //Message going epic when value of body exceed 284
  const handleBodyChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length <= 25000) {
      if (event.target.value.length > 284 && !epicAlert) {
        toast("👋🏻 You’re going epic!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
        setEpicAlert(true);
      }
      setBody(event.target.value);
    }
  };

  //Handle the upload image
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setImages(Array.from(event.target.files));
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      setRows(6);
    }
  };

  //Handle the remove of an image
  const handleImageRemove = () => {
    setImages([]);
    setPreviewImage(null);
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
    setRows(15);
  };

  //Close both preview and create when post ic clicked
  const handlePostModal = () => {
    setShowPreview(false);
    createModalActions.close();
  };

  //Handle the close of the create pop up when cancel button is clicked
  const handleCancel = () => {
    createModalActions.close();
  };

  //Handle the openning of the Preview Pop Up
  const handlePreview = () => {
    if (lead === "") {
      setShowAlert(true);
      return;
    }

    localStorage.setItem(
      "formData",
      JSON.stringify({ lead, body, previewImage })
    );

    createModalActions.close();
    setShowPreview(true);
  };

  return (
    <ReactModal
      isOpen={createModalState.isOpen}
      onAfterClose={() => {
        createModalActions.close();
      }}
      ariaHideApp={false}
      overlayClassName="bg-black bg-opacity-50"
      className="justify-center items-center flex flex-col overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-[Inter]"
    >
      <form className="relative w-full my-6 mx-auto max-w-2xl mb-3">
        <div className="relative w-full">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-[#121212] outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between py-4 px-4 border-b border-solid border-[#444444] rounded-t">
              <h3 className="text-sm text-white inline-block">Creating</h3>
              <span
                role="button"
                tabIndex={0}
                className="text-sm text-gray inline-block float-right cursor-pointer"
                onClick={handleCancel}
              >
                Cancel
              </span>
            </div>
            <div className="w-full">
              <div className="border-b border-solid border-[#444444] relative">
                <input
                  type="text"
                  placeholder="Type here..."
                  className="w-full p-4 bg-[#121212] text-white hover:outline-none focus:outline-none"
                  onChange={handleLeadChange}
                />
                {showAlert && (
                  <p className="text-red text-sm text-italic">
                    Please enter a lead
                  </p>
                )}
                <svg
                  className="absolute right-[10px] top-[20px]"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.83398 6.41536L3.50064 8.7487M3.50064 8.7487L5.83398 11.082M3.50064 8.7487H9.08398C10.1886 8.7487 11.084 7.85428 11.084 6.74971C11.084 5.042 11.084 2.79045 11.084 2.33203"
                    stroke="#8E8E8E"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="relative">
                <textarea
                  rows={8}
                  className="w-full p-4 bg-[#121212] text-white hover:outline-none focus:outline-none"
                  onChange={handleBodyChange}
                />
              </div>
              <div className="p-4">
                {previewImage && (
                  <div>
                    <img
                      src={previewImage.toString()}
                      alt="Preview"
                      className="object-fit-contain w-[680px] h-[410px]"
                    />
                  </div>
                )}
                <button
                  type="button"
                  onClick={(e) => inputFileRef.current.click()}
                  className="w-full bg-[#D3D3D3] p-3 rounded-xl justify-center flex hover:opacity-75"
                >
                  <UploadSVG></UploadSVG>
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleImageUpload}
                    ref={inputFileRef}
                    style={{ display: "none" }}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="relative w-full my-6 mx-auto max-w-2xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-[#121212] outline-none focus:outline-none">
            <div className="flex items-start py-4 px-4 rounded justify-end">
              <button
                disabled={isLoading}
                onClick={handleSubmit}
                className="inline-block py-2 px-6 text-[#00FFFF] border border-[#8E8E8E] rounded-3xl hover:bg-[#00FFFF] hover:text-[#000000] hover-border-transparent"
              >
                {isLoading && (
                  <Spinner className="w-[20px] h-[20px] inline-block" />
                )}{" "}
                Post
              </button>
            </div>
          </div>
        </div>
      </form>
    </ReactModal>
  );
};
