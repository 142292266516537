export function VoteDownIcon() {
  return (
    <svg
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.2618 15.6894C11.8478 16.1034 11.1768 16.1035 10.7626 15.6898L0.191406 5.12918L1.69015 3.62893L11.5116 13.4406L19.5129 5.43936L14.6929 5.43936C14.1073 5.43936 13.6326 4.96465 13.6326 4.37906V0L15.7532 1.8539e-07V3.31875L22.0727 3.31876C22.5015 3.31876 22.8881 3.57709 23.0522 3.9733C23.2164 4.36951 23.1256 4.82556 22.8224 5.12881L12.2618 15.6894Z"
        fill="#8E8E8E"
      />
      <path
        d="M9.35961 6.6747L9.35961 0.000415417L7.239 0.000415231L7.239 4.55409L9.35961 6.6747Z"
        fill="#8E8E8E"
      />
    </svg>
  );
}
