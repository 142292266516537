export function VoteUpIcon() {
  return (
    <svg
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.1212 0.310556C11.5352 -0.103372 12.2062 -0.10354 12.6203 0.31018L23.1916 10.8708L21.6928 12.3711L11.8713 2.55943L3.87014 10.5606H8.69006C9.27565 10.5606 9.75036 11.0354 9.75036 11.6209V16H7.62975V12.6812H1.31034C0.881482 12.6812 0.494857 12.4229 0.330742 12.0267C0.166627 11.6305 0.257342 11.1744 0.560587 10.8712L11.1212 0.310556Z"
        fill="#8E8E8E"
      />
      <path
        d="M14.0234 9.3253V15.9996H16.144V11.4459L14.0234 9.3253Z"
        fill="#8E8E8E"
      />
    </svg>
  );
}
