export function ShareIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.3676 2C2.23828 2 2.13281 2.10547 2.13281 2.23479V13.7652C2.13281 13.8945 2.23828 14 2.3676 14H13.898C14.0273 14 14.1328 13.8945 14.1328 13.7652V11.9375C14.1328 11.3852 14.5805 10.9375 15.1328 10.9375C15.6851 10.9375 16.1328 11.3852 16.1328 11.9375V13.7652C16.1328 14.9991 15.1319 16 13.898 16H2.3676C1.13371 16 0.132812 14.9991 0.132812 13.7652V2.23479C0.132812 1.0009 1.13371 1.49012e-08 2.3676 1.49012e-08H5.2124V2H2.3676Z"
        fill="#8E8E8E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.4624 1.49012e-08H16.1328V6.6875C16.1328 7.23978 15.6851 7.6875 15.1328 7.6875C14.5805 7.6875 14.1328 7.23978 14.1328 6.6875V2H8.4624V1.49012e-08Z"
        fill="#8E8E8E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.8399 0.292893C16.2304 0.683417 16.2304 1.31658 15.8399 1.70711L9.71492 7.83211C9.3244 8.22263 8.69123 8.22263 8.30071 7.83211C7.91018 7.44158 7.91018 6.80842 8.30071 6.41789L14.4257 0.292893C14.8162 -0.0976311 15.4494 -0.0976311 15.8399 0.292893Z"
        fill="#8E8E8E"
      />
    </svg>
  );
}
