// UploadSVG.tsx
import React from "react";

const UploadSVG: React.FC = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="19" cy="19" r="19" fill="black" />
      <path d="M11 21V27H27V21" stroke="white" stroke-width="2" />
      <path
        d="M25.0001 16.0001L19 10L12.9999 16.0001"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path d="M19 10L19 23" stroke="white" stroke-width="2" />
    </svg>
  );
};

export default UploadSVG;
