import { useEffect, useState } from "react";

const FetchArticle = (id) => {
  const [article, setArticle] = useState(null);

  useEffect(() => {
    fetch(
      "https://publisher.ashybeach-de0b57bf.eastus.azurecontainerapps.io/api_v1/article/" +
        id
    )
      .then((response) => response.json())
      .then((data) => {
        const formattedArticles = [data.data].map((article) => {
          let imageUrl = "";
          if (
            article.medias &&
            article.medias.length > 0 &&
            article.medias[0].url
          ) {
            imageUrl = article.medias[0].url;
          }
          const date = new Date(article.createdAt);
          const formattedDate = date.toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
          return {
            imageUrl: imageUrl,
            title: article.lead,
            body: article.body,
            fullName: article.author.firstName + " " + article.author.lastName,
            createdAt: formattedDate,
          };
        });

        setArticle(formattedArticles[0]);
      });
  }, []);

  return article;
};

export default FetchArticle;
