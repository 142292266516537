import CityverseLogo from "../utilities/images/cityverse.png";
import { FaSearch } from "react-icons/fa";
import { UserIcon } from "../utilities/icons/UserIcon";
import { FunnelIcon } from "../utilities/icons/FunnelIcon";
import { BellRoundIcon } from "../utilities/icons/BellRoundIcon";
import { PlusRoundIcon } from "../utilities/icons/PlusRoundIcon";
import { useCreateModal } from "../state/CreateModalState";

export const NavbarCityverse = () => {
  const [createModalState, createModalActions] = useCreateModal();

  // @ts-ignore
  return (
    <>
      <header className="font-[Inter]">
        <div className="flex flex-wrap lg:flex-row lg:items-center lg:space-y-0 lg:justify-center px-8 bg-[#000000] py-3 w-full relative space-y-4">
          <div className="p-[4px] w-full lg:w-30 lg:absolute lg:left-0">
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img src={CityverseLogo} alt={"logo"} className="max-w-[240px]" />
          </div>
          <div className="flex items-center justify-center space-x-4 w-full z-30">
            <div>
              <button
                className="createButton flex items-center justify-center bg-transparent border-0 rounded-[25px] cursor-pointer pl-[8px] pr-[12px] h-[40px] hover:opacity-75"
                onClick={(state) => {
                  if (!state) {
                    createModalActions.close();
                  } else {
                    createModalActions.open();
                  }
                }}
              >
                <PlusRoundIcon />
                <span className="ml-[10px] text-[20px] text-black">Create</span>
              </button>
            </div>
            <form className="flex m-auto max-w-[580px] w-full">
              <div className="flex-1 relative">
                <input
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  className="searchInput w-full pl-10 pr-5 py-3 rounded-[45px] mr-4 color-[#8E8E8E] placeholder-[#8E8E8E] text-white"
                />
                <FaSearch className="absolute left-[10px] top-[50%] translate-y-[-50%] fill-[#8E8E8E]" />
              </div>
              <div className="flex items-center relative">
                <span>
                  <UserIcon />
                </span>
              </div>
            </form>
          </div>
          <div className="w-full lg:w-auto lg:absolute lg:right-0 text-center space-x-3 px-3">
            <span className="inline-block">
              <BellRoundIcon />
            </span>
            <span className="inline-block">
              <FunnelIcon />
            </span>
          </div>
        </div>
      </header>
    </>
  );
};
