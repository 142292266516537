export function PlusRoundIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="14" r="14" fill="black" />
      <rect
        x="7"
        y="13.125"
        width="14"
        height="1.75"
        rx="0.875"
        fill="#2AF6FF"
      />
      <rect
        x="14.875"
        y="7"
        width="14"
        height="1.75"
        rx="0.875"
        transform="rotate(90 14.875 7)"
        fill="#2AF6FF"
      />
    </svg>
  );
}
