import React, { useState } from "react";

import SrIcon from "../utilities/icons/SrIcon.svg";
import SlIcon from "../utilities/icons/SlIcon.svg";
import { VoteUpIcon } from "../utilities/icons/VoteUpIcon";
import { VoteDownIcon } from "../utilities/icons/VoteDownIcon";
import { ShareIcon } from "../utilities/icons/ShareIcon";
import { FunnelIcon } from "../utilities/icons/FunnelIcon";

interface NewsArticle {
  imageUrl: string;
  title: string;
  body: string;
  fullName: string;
  createdAt: string;
}

interface NewsStackProps {
  newsArticles: NewsArticle[];
}

const NewsStack: React.FC<NewsStackProps> = ({ newsArticles }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSlideLeft = () => {
    setCurrentIndex((oldIndex) =>
      oldIndex === 0 ? newsArticles.length - 1 : oldIndex - 1
    );
  };

  const handleSlideRight = () => {
    setCurrentIndex((oldIndex) =>
      oldIndex === newsArticles.length - 1 ? 0 : oldIndex + 1
    );
  };
  return (
    <div className="w-full max-w-[680px] mx-auto mt-[60px] break-words">
      <div className="rounded-[10px] bg-[#181818]">
        <div className="flex justify-center rounded-tl-[10px] rounded-tr-[10px] bg-[#181818]">
          <img
            alt="Logo"
            src={newsArticles[currentIndex].imageUrl}
            className="w-[680px] h-[408px] object-fit-contain bg-[#181818] rounded-tl-[10px] rounded-tr-[10px]"
          />
        </div>
        <div className="p-[15px] bg-[#181818] rounded-bl-[10px] rounded-br-[10px]">
          <div className="flex items-center text-white pt-[5px] font-[16px]">
            <div className="w-[15px] h-[15px] border-[2px] border-[#FFFFFF] rounded-[50%] mr-[10px]"></div>
            <h3>{newsArticles[currentIndex].fullName}</h3>
            <h4 className="uppercase ml-[20px] text-[14px] text-bold">
              {newsArticles[currentIndex].createdAt}
            </h4>
          </div>
          <h2 className="my-[15px] text-[32px] font-bold leading-10 text-white">
            {newsArticles[currentIndex].title}
          </h2>
          <p className="text-white">{newsArticles[currentIndex].body}</p>
        </div>
      </div>
      <div className="rounded-[10px] bg-[#181818] flex justify-between p-[15px] rounded-[10px] mt-[5px]">
        <button
          className="text-white py-[10px] px-[15px] bg-no-repeat bg-[#181818] bg-contain hover:opacity-50"
          style={{ backgroundImage: `url(${SlIcon})` }}
          onClick={handleSlideLeft}
        >
          Slide
        </button>
        <div className="flex items-center">
          <div className="flex w-[50px] items-center">
            <FunnelIcon />
            <span className="text-[#8E8E8E] ml-2">44</span>
          </div>
          <div className="flex w-[80px] mx-4 items-center">
            <VoteUpIcon />
            <span className="text-[#8E8E8E] mx-2">44</span>
            <VoteDownIcon />
          </div>
          <div className="flex w-[80px] items-center">
            <ShareIcon />
            <span className="text-[#8E8E8E] ml-2">44</span>
          </div>
        </div>
        <button
          className="text-white py-[10px] px-[15px] bg-no-repeat bg-[#181818] bg-contain hover:opacity-50"
          style={{ backgroundImage: `url(${SrIcon})` }}
          onClick={handleSlideRight}
        >
          Slide
        </button>
      </div>
    </div>
  );
};

export default NewsStack;
