import { NavbarCityverse } from "./navbar/Navbar";
import { useContext, useState } from "react";
import useFetchData from "./utilities/FetchData";

import "../public/styles.css";
import { CreateModal } from "./components/CreateModal";

import { ToastContainer } from "react-toastify";
import { Outlet } from "react-router-dom";
import { useCreateModal } from "./state/CreateModalState";

export default function Root() {
  const [createModalState, createModalActions] = useCreateModal();

  //Fetch User ID and Check Token
  const authenticationData = useFetchData(createModalState.isOpen);

  return (
    <>
      {/* Navbar */}
      <NavbarCityverse />

      <Outlet />

      {/* Add authentication when the create button is clicked */}
      {/* Create Modal Pop up */}
      <CreateModal />

      <ToastContainer />
    </>
  );
}
