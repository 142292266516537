export function UserIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16.0003" cy="13.0901" r="4.36358" fill="#8E8E8E" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.2 16C3.2 8.93075 8.93075 3.2 16 3.2C23.0692 3.2 28.8 8.93075 28.8 16C28.8 19.7852 27.157 23.1866 24.5452 25.53C24.0936 24.4692 23.4401 23.4938 22.609 22.6627C20.9041 20.9578 18.5918 20 16.1808 20C13.7697 20 11.4574 20.9578 9.75251 22.6627C8.86107 23.5541 8.17389 24.6116 7.72107 25.7625C4.95519 23.4146 3.2 19.9123 3.2 16ZM25.2017 29.0909C22.599 30.9237 19.4252 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 21.3807 29.344 26.141 25.2715 29.0415C25.2716 29.0579 25.2717 29.0744 25.2717 29.0909H25.2017Z"
        fill="#8E8E8E"
      />
    </svg>
  );
}
