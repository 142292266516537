import NewsStack from "./components/NewsStack";
import { Outlet } from "react-router-dom";
import FetchArticles from "./utilities/FetchArticles";

export function Home() {
  const articles = FetchArticles();

  return (
    <>
      {/* NewsStack */}
      <main className="font-[Inter]">
        {articles.length > 0 && <NewsStack newsArticles={articles} />}
      </main>
    </>
  );
}
