import { createBrowserRouter } from "react-router-dom";
import Root from "./root.component";
import { Article } from "./sections/Articles/Article";
import { Home } from "./Home";

export const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Root />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/:id",
          element: <Article />,
        },
      ],
    },
  ],
  {
    basename: "/home",
  }
);
