interface DataSubmissionProps {
  lead: string;
  body: string;
  previewImage: string | ArrayBuffer;
}

export async function dataSubmission({
  lead,
  body,
  previewImage,
}: DataSubmissionProps) {
  const data: any = {
    lead: lead,
    body: body,
  };

  if (typeof previewImage === "string") {
    data.media1 = previewImage;
  } else if (previewImage instanceof ArrayBuffer) {
    data.media1 = new Blob([new Uint8Array(previewImage)], {
      type: "image/jpeg",
    });
  }

  const token = window.localStorage.getItem("token");

  if (!token) {
    throw new Error("Error: Token not found in local storage");
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  let response;
  try {
    response = await Promise.race([
      fetch(
        "https://publisher.ashybeach-de0b57bf.eastus.azurecontainerapps.io/api_v1/article/article",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
        }
      ),
      new Promise(
        (_, reject) =>
          setTimeout(
            () => reject(new Error("Error: Waiting time exceeded")),
            10000
          ) // 10000 ms = 10 secondes
      ),
    ]);
  } catch (error) {
    throw new Error(`Network error: ${error}`);
  }

  if (!response.ok) {
    const message = await response.text();
    throw new Error(
      `Error: ${response.status} ${response.statusText}, Message: ${message}`
    );
  }
}
