import React, { useState } from "react";
import { VoteUpIcon } from "../utilities/icons/VoteUpIcon";
import { VoteDownIcon } from "../utilities/icons/VoteDownIcon";
import { ShareIcon } from "../utilities/icons/ShareIcon";
import { FunnelIcon } from "../utilities/icons/FunnelIcon";
import { BackIcon } from "../utilities/icons/BackIcon";
import { useNavigate } from "react-router-dom";

interface NewsArticle {
  imageUrl: string;
  title: string;
  body: string;
  fullName: string;
  createdAt: string;
}

interface ArticlePreviewProps {
  article: NewsArticle;
}

const ArticlePreview: React.FC<ArticlePreviewProps> = ({ article }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const navigate = useNavigate();

  const handleBack = (e) => {
    e.preventDefault();
    navigate("/");
  };

  return (
    <div className="w-full max-w-[680px] mx-auto mt-[60px] break-words">
      <div className="rounded-[10px] bg-[#181818]">
        <div className="flex justify-center rounded-tl-[10px] rounded-tr-[10px] bg-[#181818]">
          <img
            alt="Logo"
            src={article.imageUrl}
            className="w-[680px] h-[408px] object-fit-contain bg-[#181818] rounded-tl-[10px] rounded-tr-[10px]"
          />
        </div>
        <div className="p-[15px] bg-[#181818] rounded-bl-[10px] rounded-br-[10px]">
          <div className="flex items-center text-white pt-[5px] font-[16px]">
            <div className="w-[15px] h-[15px] border-[2px] border-[#FFFFFF] rounded-[50%] mr-[10px]"></div>
            <h3>{article.fullName}</h3>
            <h4 className="uppercase ml-[20px] text-[14px] text-bold">
              {article.createdAt}
            </h4>
          </div>
          <h2 className="my-[15px] text-[32px] font-bold leading-10 text-white">
            {article.title}
          </h2>
          <p className="text-white">{article.body}</p>
        </div>
      </div>
      <div className="rounded-[10px] bg-[#181818] flex justify-between p-[15px] rounded-[10px] mt-[5px]">
        <button
          className="text-white py-[10px] px-[15px] bg-no-repeat bg-[#181818] bg-contain hover:opacity-50"
          onClick={handleBack}
        >
          <BackIcon /> Back
        </button>
        <div className="flex items-center">
          <div className="flex w-[50px] items-center">
            <FunnelIcon />
            <span className="text-[#8E8E8E] ml-2">44</span>
          </div>
          <div className="flex w-[80px] mx-4 items-center">
            <VoteUpIcon />
            <span className="text-[#8E8E8E] mx-2">44</span>
            <VoteDownIcon />
          </div>
          <div className="flex w-[80px] items-center">
            <ShareIcon />
            <span className="text-[#8E8E8E] ml-2">44</span>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default ArticlePreview;
