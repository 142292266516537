import { useEffect, useState } from "react";

const useFetchData = (modalState) => {
  const [data, setData] = useState(null);
  const token = window.localStorage.getItem("token");

  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!modalState) {
        return;
      }

      if (!token) {
        window.location.href = "/auth/login";
      } else {
        try {
          const response = await fetch(
            "https://publisher.ashybeach-de0b57bf.eastus.azurecontainerapps.io/api_v1/auth/is_authenticated",
            options
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          setData(data);
        } catch (error) {
          window.location.href = "/auth/login";
        }
      }
    };

    fetchData();
  }, [token, modalState]);

  return data;
};

export default useFetchData;
