export function FunnelIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_686_7015)">
        <path
          d="M7 11L13.7321 17.7321V22.4842"
          stroke="#8E8E8E"
          stroke-width="2.2"
          stroke-linejoin="round"
        />
        <path
          d="M25.6094 11L18.8772 17.7321V22.4842"
          stroke="#8E8E8E"
          stroke-width="2.2"
          stroke-linejoin="round"
        />
        <circle cx="16" cy="16" r="15.5" stroke="#242424" />
      </g>
      <defs>
        <clipPath id="clip0_686_7015">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
